import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { authModuleConfig } from './modules/login/auth.config';
import {
  RSA_PARAMETER,
  TokenInterceptor,
  VerifySignInterceptor,
} from '@deuna/bp-sh-helpers-ng';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(authModuleConfig),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VerifySignInterceptor,
      multi: true,
    },
    {
      provide: RSA_PARAMETER,
      useValue: localStorage.getItem("RSA_PARAMETER"),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
